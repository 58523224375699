:root {
  --primary-color: #14182C;
  --secondary-color: #931786;
  --text-color: #FFFFFF;
}

.secondar-color {
  color: var(--secondary-color);
}

.btn {
  background-color: var(--secondary-color) !important;
  font-size: 18px !important;
  text-transform: none !important;
}

.btn:hover {
  background-color: #D822C5 !important;
}

.w-35 {
  width: 35%;
}

/* <------- Navbar ------> */
.logo {
  height: 70px;
  width: 70px;
}

.nav-list {
  height: 70px;
  list-style: none;
  color: var(--text-color);
}

.nav-item:hover {
  border-bottom: 2px solid var(--secondary-color);
}

.lang {
  height: 35px;
  width: 35px;
}

.nav-mobile {
  display: none !important;
}


/* <--------------------> */

/* <------- Header ------> */
.header {
  height: 90vh;
}

.header-info {
  height: 45vh;
}

.header-right {
  width: 50%;
}

.modalnav {
  display: none;
}

.header-left {
  width: 50%;
  position: relative;
}

.header-title {
  font-size: 46px;
  font-weight: 700;
}

.ball-1 {
  position: absolute;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: radial-gradient(#D40AE2, #9A0495);
  animation: bounce-1 9s;
  animation-iteration-count: infinite;
  top: 150px;
  left: 1050px;
}

.ball-2 {
  position: absolute;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: radial-gradient(#9C0EBF, #9904DB);
  animation: bounce-2 9.5s;
  animation-iteration-count: infinite;
  top: 200px;
  left: 1175px;
}

.ball-3 {
  position: absolute;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: radial-gradient(#D316C0, #9A02A5);
  animation: bounce-3 10s;
  animation-iteration-count: infinite;
  top: 250px;
  left: 1300px;
}


/* <--------------------> */

/* <------- About me ------> */

.header-about {
  height: 45vh;
}

.aboutme-img {
  height: 40vh;
}

/* <--------------------> */

/* <------- Portfolio ------> */

.projectimg {
  height: 250px;
  width: 560px;
  object-fit: cover;
}

.big-display {
  display: block;
}

.small-display {
  display: none;
} 

/* <--------------------> */

/* <------- Footer ------> */

.footer {
  border-top: 3px solid var(--secondary-color);
}
/* <--------------------> */

/* <------- Keyframes ------> */
@keyframes bounce-1 {
	0% {
    top: 150px;
  }
  50% {
    top: 200px;
  }
  100% {
    top: 150px;
  }

}

@keyframes bounce-2 {
	0% {
    top: 200px;
  }
  50% {
    top: 250px;
  }
  100% {
    top: 200px;
  }

}

@keyframes bounce-3 {
	0% {
    top: 250px;
  }
  50% {
    top: 300px;
  }
  100% {
    top: 250px;
  }

}
/* <--------------------> */

/* <------- Media querys ------> */
@media only screen and (max-width: 768px) {
  .header {
    height: 70vh;
  }

  .header-info {
    height: 35vh;
  }

  .header-left {
    width: 100%;
  }
  
  .ball-1 {
    display: none;
  }
  
  .ball-2 {
    display: none;
  }
  
  .ball-3 {
    display: none;
  }

  .aboutme-img {
   display: none;
  }

  .header-about {
    display: block;
    height: 35vh !important;
  }

  .about-text {
    margin-left: 0 !important;
  } 

  .portfolio-item {
    display: block !important;
  }

  .w-35 {
    width: 100%;
    margin-left: 0 !important;
  }

  .big-display {
  display: none;
  } 

  .small-display {
  display: block;
  } 
}

@media only screen and (max-width: 425px) {
  .logo {
    display: none;
  }

  .logo-mobile {
    display: block;
    height: 35px;
    width: 35px;
  }
  
  .nav-list {
    display: none !important;
  }

  .nav-mobile {
    display: flex !important;
  }

  .fa-bars {
    color: var(--text-color);
  }
  .header-title {
    font-size: 36px;
    font-weight: 700;
  }

  .header {
    height: 95vh;
  }

  .header-left {
    width: 100%;
  }

  .header-info {
    display: block !important;
    height: 35vh;
  }

  .header-about {
    height: 60vh !important;
  }

  .projectimg {
    height: 200px;
    width: 300px;
    object-fit: cover;
  }

  .w-50 {
    width: 100% !important;
  }

  .me {
    display: none;
  }

}



/* <--------------------> */

